<template>
  <div>
    <b-modal
      id="sus-batch-file-list-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hide="hideModal"
    >
      <div class="modal-header">
        <div>
          <p class="modal-title">Arquivos anexados</p>
        </div>
        <div>
          <b-button
            variant="link"
            class="attach-button"
            @click="openSusGuideFileModal"
          >
            <Clip class="clip" /> Anexar arquivo
          </b-button>
          <v-close class="icon" @click="hideModal" />
        </div>
      </div>
      <div class="modal-body list-area">
        <ul class="list">
          <perfect-scrollbar>
            <li
              v-for="file of files"
              :key="file.id"
              @click="ev => openFileInNewTab(ev, file)"
            >
              <div class="d-flex container image-item">
                <div class="container-data-files">
                  <div class="data-files">
                    <p class="name">{{ file.filename }}</p>
                  </div>
                  <p class="info">
                    {{
                      moment(file.created_at).format('DD [de] MMMM [de] YYYY')
                    }}
                  </p>
                </div>
                <div>
                  <Edit
                    class="sus-batch-file-option-edit"
                    @click="ev => showEditDocumentModal(ev, file)"
                  />
                  <Delete
                    class="sus-batch-file-option-delete"
                    @click="ev => onDeleteClick(ev, file)"
                  />
                </div>
              </div>
            </li>
          </perfect-scrollbar>
        </ul>
      </div>
    </b-modal>

    <v-delete-modal
      type="documento"
      :description="toDelete && toDelete.filename ? toDelete.filename : ''"
      :confirmedDelete="deleteFile"
    />
  </div>
</template>

<script>
import Edit from '@/assets/icons/edit.svg'
import Delete from '@/assets/icons/delete.svg'
import Close from '@/assets/icons/close.svg'
import Clip from '@/assets/icons/clip.svg'
import DeleteModal from '@/components/General/DeleteModal'

export default {
  name: 'SusBatchFileListModal',
  props: {
    batch: Object,
    files: Array
  },
  components: {
    Edit,
    Delete,
    Clip,
    'v-close': Close,
    'v-delete-modal': DeleteModal
  },
  data() {
    return {
      file: {},
      toDelete: {},
      activeFile: null
    }
  },
  methods: {
    hideModal() {
      this.activeFile = null
      this.$emit('hide-file-list-modal', this.files)
      this.$bvModal.hide('sus-batch-file-list-modal')
    },
    openSusGuideFileModal() {
      this.$emit('open-sus-batch-file-modal', this.susGuide)
    },
    async openFileInNewTab(ev, file) {
      ev.stopPropagation()
      const temporaryUrl = await this.getDocTemporaryUrl(file)
      window.open(temporaryUrl, '_blank')
    },
    async getDocTemporaryUrl(susGuideFile) {
      try {
        const response = await this.api.getDocTemporaryUrl(susGuideFile.path)
        return response.data
      } catch (error) {
        throw new Error(400)
      }
    },
    onDeleteClick(ev, file) {
      ev.stopPropagation()
      if (!file.id) return
      this.toDelete = file
      this.$bvModal.show('delete-modal')
    },
    async deleteFile(confirm) {
      if (confirm) {
        const isLoading = this.$loading.show()
        try {
          await this.api.deleteNewSusBatchFile(this.toDelete.id)
          this.activeFile = null
          this.$emit('delete-file', this.toDelete)
          this.$toast.success('Arquivo deletado com sucesso')
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      }
      this.toDelete = null
      this.$bvModal.hide('delete-modal')
    },
    showEditDocumentModal(ev, file) {
      ev.stopPropagation()
      this.file = file
      this.$emit('edit-sus-batch-file', this.file)
    }
  }
}
</script>

<style lang="scss">
#sus-batch-file-list-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;
      min-height: 400px !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }
    }
  }

  .attach-button {
    margin-right: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }

  .list-area {
    margin: -24px 0 24px 0;

    .list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        padding: 25px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:before {
          width: calc(100% - 48px);
          height: 1px;
          background-color: var(--neutral-200);
          position: absolute;
          bottom: 0;
          left: 24px;
          content: ' ';
        }

        &:hover {
          background-color: var(--neutral-100);
          border-radius: 8px;
        }

        .container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .container-data-files {
            .data-files {
              display: flex;
              cursor: pointer;
              flex-direction: row;
              align-items: center;
            }
          }
        }

        .name {
          font-weight: 600;
          font-size: 16px;
          color: var(--type-active);
        }

        .info {
          font-weight: 600;
          font-size: 12px;
          color: var(--type-placeholder);
          white-space: nowrap;

          > span {
            width: 8px;
            height: 8px;
            margin-right: 8px;
            background-color: var(--states-success);
            border-radius: 4px;
            display: inline-block;

            &.orange {
              background-color: var(--light-orange-500);
            }
          }
        }
      }
    }
  }
  .sus-batch-file-option-edit {
    width: 22px;
    height: 22px;
    cursor: pointer;
    stroke: var(--blue-500);
    margin-right: 10px;
  }
  .sus-batch-file-option-delete {
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-left: 5px;
  }
}
</style>
