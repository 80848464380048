<template>
  <div>
    <v-sus-batch-file-modal
      :batch="batch"
      :file="file"
      @uploadNewDocument="uploadNewDocument"
      @update-file="updateFile"
      @clean-modal="clearFile"
    />

    <v-sus-batch-file-list-modal
      :batch="batch"
      :files="files"
      @open-sus-batch-file-modal="openDocumentModal"
      @edit-sus-batch-file="openEditFileModal"
      @delete-file="deleteFile"
      @hide-file-list-modal="hideFileListModal"
    />
  </div>
</template>

<script>
import SusBatchFileModal from '@/components/Schedule/SusBatchFileModal'
import SusBatchFileListModal from '@/components/Schedule/SusBatchFileListModal'

export default {
  name: 'SusBatchFilesContainer',
  props: {
    batch: Object,
    susBatches: Array,
    files: Array,
    file: Object,
    openDocumentModal: Function,
    openEditFileModal: Function,
    clearFile: Function
  },
  components: {
    'v-sus-batch-file-modal': SusBatchFileModal,
    'v-sus-batch-file-list-modal': SusBatchFileListModal
  },
  methods: {
    deleteFile(file) {
      const susBatch = this.susBatches.find(
        guide => guide.id === file.sus_guide_id
      )
      const index = this.files.findIndex(guide => guide.id === file.id)
      this.files.splice(index, 1)
      const index2 = this.susBatches.findIndex(
        guide => guide.id === file.sus_guide_id
      )
      this.$set(this.susBatches, index2, susBatch)
      this.clearFile()
    },
    updateFile(file) {
      console.log(file)
      const susBatch = this.susBatches.find(
        batch => batch.id === file.sus_batch_id
      )
      const index = this.files.findIndex(batch => batch.id === file.id)
      this.files.splice(index, 1, file)

      const index2 = this.susBatches.findIndex(
        batch => batch.id === file.sus_batch_id
      )
      this.$set(this.susBatches, index2, susBatch)
      this.clearFile()
    },
    uploadNewDocument(file) {
      /* const susBatch = this.susBatches.find(
        batch => batch.id === file.sus_batch_id
      )
      susBatch.files_count++
      const index = this.susBatches.findIndex(
        batch => batch.id === file.sus_batch_id
      )
      this.$set(this.susBatches, index, susBatch) */
      if(file) this.files.push(file)
      this.clearFile()
    },
    hideFileListModal(files) {
      this.$emit('hide-file-list-modal', files)
    }
  }
}
</script>